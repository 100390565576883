<template>
  <div class="pad-home">
    <HeaderBar title="我的积分" :showLeft="true"></HeaderBar>
    <div class="points">
      <div class="points-title">我的积分</div>
      <div class="points-title-en">My points</div>
      <div class="points-num">
        <span class="points-num-data">{{score}}</span>
        <span class="points-num-unit">积分</span>
      </div>
    </div>
     <!-- <div id="connectDiv">
        <div class="textMsg"><span>类型：</span><input type="radio" name="UserType" checked="checked" value="0" />联系司机&nbsp;&nbsp;&nbsp;&nbsp;<input type="radio" name="UserType" value="1" />联系乘客</div>
        <div class="textMsg">
            <span>司机ID：</span><input type="text" id="txtPmid" value="" style="height:20px;width:200px;border:solid 1px #ccc;" />
        </div>
        <div class="textMsg">
            <span>乘客ID：</span><input type="text" id="txtUmid" value="" style="height:20px;width:200px;border:solid 1px #ccc;" />
        </div>

        <div class="textMsg"><input type="button" style="margin:5px;height:50px;width:150px;font-size:16px;text-align:center;" @click="LoadBind();" value="连接（Connect）" /></div>

    </div>
    <div id="msgInputDiv" style="display:none">
        <div class="textMsg">
            <a>请输入消息：</a> <input type="text" id="message" /> <button type="button" style="margin:5px;height:50px;width:150px;font-size:16px;text-align:center;" @click="doSend();">发送</button>
        </div>
        <div id="outputDiv" style="margin-top:20px">

        </div>
    </div> -->
  </div>
  
</template>

<script>
import HeaderBar from '@/components/headerbar/headerbar'
import { getStore } from '../../lib/storage';

export default {
  name: 'score',
  components:{HeaderBar},
  data () {
    return {
      // score: 0
    }
  },
  computed: {
    score () {
      return JSON.parse(getStore('userInfo')).JifenBalance
    }
  },
  methods: {
    appendHistory(msg) {
    var outputElement = document.getElementById("outputDiv");
    var itemDiv = document.createElement("div");
    itemDiv.innerText = msg;

    outputElement.appendChild(itemDiv);
},
 LoadBind() {

var Pmid;
var Umid;
var UserType = 0;
var _this=this
    debugger
    Pmid = document.getElementById("txtPmid").value;
    var type = document.getElementsByName("UserType");
    for (var i = 0; i < type.length; i++) {
        if (type[i].checked == true) {
            UserType = type[i].value;
        }
    }
    Umid = document.getElementById("txtUmid").value;

    var connectiDiv = document.getElementById("connectDiv");
    var messageDiv = document.getElementById("msgInputDiv");


    self.webSocket = new WebSocket("wss://hxapitest.raccoontrip.com/Api/ChatSend?Pmid=" + Pmid + "&Umid=" + Umid + "&UserType=" + UserType);
    self.webSocket.onopen = function () {
        connectiDiv.style.display = "none";
        messageDiv.style.display = "block";
        //appendHistory("�����ӣ�");
    }

    self.webSocket.onerror = function () {
        console.log("Web Socket ����");
    }

    self.webSocket.onmessage = function (event) {
       _this.appendHistory(event.data);
    }

    self.webSocket.onclose = function () {
         _this.appendHistory("���뿪!");
    }

},
doSend() {
    
    var message = document.getElementById("message");
    self.webSocket.send(message.value);
    //appendHistory("��" + message.value);
},
getQueryVariable(variable) {
    
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
},

  },
  mounted () {
    
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/mixin.less';
 body{
            text-align:left;
            line-height:25px;
            font-family:Arial,"微软雅黑";
        }
        * {
            text-align: left;
            line-height: 25px;
            font-family: Arial,"微软雅黑";
        }
        .textMsg{
            line-height:1.2em;
            margin:5px;
            padding:0px;
        }
.pad-home{
  padding-top: 94px;
}
.points {
  .size(690px, 400px);
  background: url('../../assets/integrals_bg.png') 0 0 no-repeat;
  background-size: 690px 400px;
  margin: 32px auto;
  padding: 40px 60px 60px 40px;
  box-sizing: border-box;

  &-title {
    font-size: 40px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 56px;

    &-en {
      margin-bottom: 124px;
      font-size: 30px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 42px;
    }
  }

  &-num {
    text-align: right;
    &-data {
      font-size: 60px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 84px;
    }
    &-unit {
      font-size: 30px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 84px;
    }
  }
}
</style>

